import React, { useState } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Slider from "react-slick"
import Fade from "react-reveal/Fade"
import { isAndroid, isMobile } from "react-device-detect"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../../components/core/seo"
import useWindowSize from "../../components/hooks/windowSize"

import linkBackgroundImage from "../../images/graphic-link-bg-cleancio.png"

import mobileA from "../../images/work/cleancio/work-cleancio-0-mobile.jpg"
import mobileB from "../../images/work/cleancio/work-cleancio-1-mobile.jpg"
import mobileC from "../../images/work/cleancio/work-cleancio-2-mobile.jpg"
import mobileD from "../../images/work/cleancio/work-cleancio-3-mobile.jpg"
import mobileE from "../../images/work/cleancio/work-cleancio-4-mobile.jpg"

import socialOG from "../../images/social-opengraph-work-cleancio.png"

import FixedBackground from "../../components/elements/image-background-fixed"
import Layout from "../../components/core/layout"
import PrimaryImageWE from "../../components/work/image-work-cleancio"
import Contact from "../../components/contact"
import ShowcaseX from "../../components/elements/image-work-X-cleancio"
import PrimaryButton from "../../components/button/primary-button"
import NextPrev from "../../components/button/next-prev-buttons"

// Components

const PrimarySpacer = styled.div`
  margin-top: 200px;
`

const HeaderSpacer = styled.div`
  height: 120px;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const FlexSpacer = styled.div`
  flex-grow: 1;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const SectionHeader = styled.div`
  font-family: "Nitti Bold";
  margin-top: 65px;
  margin-bottom: 35px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`

const SectionContent = styled.div`
  line-height: 1rem;

  ${media.lessThan("medium")`
      width: 300px;
      font-size: 13px;
      line-height:1.1rem;
  `}
`

const WorkClient = styled.div`
  font-family: "Roobert Bold";
  padding-top: 140px;
  font-size: 3.375rem;
  line-height: 2.9rem;
  white-space: nowrap;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 2.1rem;
    line-height: 2rem;
    margin-top: 90px;
    padding-top: 0px;
    width: 200px;
  `}
`

const WorkSubhead = styled.div`
  font-family: "Roobert";
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.1;

  ${media.lessThan("medium")`
        width: 250px;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 14px;
    `}
`

const SectionHorizontalRule = styled.div`
  height: 1px;
  width: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #535663;
  margin-left: auto;
  margin-right: auto;

  ${media.lessThan("medium")`
        margin-top: 0px;
    `}
`

// Main Section

const IndexContainer = styled.div`
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
`

const MainContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.lessThan("medium")`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const MainLeft = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding-right: 80px;

  ${media.lessThan("medium")`
        padding-right: 0px;
    `}
`

const VerticalRule = styled.div`
  height: 400px;
  width: 1px;
  bottom: 0px;
  background-color: #535663;

  ${media.lessThan("medium")`
    width: 4px;
    display: none;
  `}
`

const HorizontalRule = styled.div`
  display: none;

  ${media.lessThan("medium")`
    display: block;
    width: 4px;
    height: 1px;
    width: 100%;
    margin-top: 80px;
    background-color: #535663;
  `}
`

const MainRight = styled.div`
  display: flex;
  padding-left: 80px;
  padding-top: 20px;
  flex-grow: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: baseline;

  ${media.lessThan("medium")`
        padding-left: 0px;
        padding-top: 0px;
        flex-grow: 0;
        max-width: 90%;
        margin-bottom: 80px;
    `}
`

const SectionImpact = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

const ImpactContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
`

const ImpactTotal = styled.div`
  font-family: "Roobert Bold";
  font-size: 40px;

  ${media.lessThan("1000px")`
        font-size: 30px;
    `}

  ${media.lessThan("medium")`
        font-size: 26px;
    `}
`

const ImpactDescription = styled.div`
  font-family: "Nitti Bold";
  font-size: 13px;
  line-height: 0.8rem;
  margin-top: 12px;
  max-width: 120px;

  ${media.lessThan("1000px")`
        font-size: 11px;
    `}

  ${media.lessThan("medium")`
        font-family: "Nitti Normal";
        font-size: 11px;
        max-width: 80px;
    `}
`

// Middle Section

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${media.lessThan("medium")`
        flex-direction: column;
        justify-content: stretch;
    `}
`

const ClientQuote = styled.div`
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
  pointer-events: none;

  ${media.lessThan("medium")`
        font-size: 20px;
        width: 80vw;
        margin-bottom: 25px;
    `}
`

const ClientName = styled.div`
  text-align: center;
  line-height: 1.1;
  pointer-events: none;

  ${media.lessThan("medium")`
        margin-bottom: 80px;
    `}
`

const PhaseDisciplineContainer = styled.ul`
  justify-self: baseline;
`

const PhaseDisciplines = styled.li`
  line-height: 0.5;
  white-space: nowrap;

  ${media.lessThan("1220px")`
    font-size: 12px;
  `}

  ${media.lessThan("medium")`
    font-size: 11px;
  `}
`

// Showcase

const ShowcaseContainer = styled.div`
  display: flex;
  font-family: "Roobert Bold";
  text-align: center;
  font-size: 40px;
  height: 80vh;

  align-items: center;
  justify-content: center;
  margin-top: 80px;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  ${media.lessThan("1000px")`
        height: 60vh;
        margin-top: 200px;
    `}

  ${media.lessThan("medium")`
        margin-top: -40px;
        flex-direction: column;
        margin-bottom: 80px;
        height: 60vh;
    `}
    min-height: 450px;
`

const ShowcaseSliderMobile = styled(Slider)`
  ${media.greaterThan("medium")`
        width: 70vw;
        max-width: 950px;
        height: 800px;
        border-radius: 16px;
        margin-left: 10vw;
        margin-top: 140px;
    `}

  ${media.lessThan("medium")`
        margin-left: -10px;
        margin-top: 40px;
        width: 200px;
        height: 410px;
        overflow: hidden;
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        border-radius: 10px;
    `}
`

const MobileSlide = styled.div`
  ${media.greaterThan("medium")`
        display: flex;
        justify-items: center;
        align-items: center;
        padding-top: 60px;
        padding-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
        transition: .25s all ease-out;
    `}
`

const MobileInner = styled.div`
  ${media.greaterThan("medium")`
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        border-radius: 10px;
        height: 90%;
    `}
`

const MobileIMG = styled.img`
  margin-bottom: 0px;
`

const ShowcaseSliderShadow = styled.div`
  width: 200px;
  height: 437px;
  border-radius: 16px;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 380px;
  margin-top: 60px;

  ${media.greaterThan("medium")`
        display: none;
    `}

  ${media.lessThan("medium")`
        border-radius: 10px;
        height: 383px;
        margin-left: -5px;
        margin-top: 36px;
    `}
`

const ProjectCounter = styled.div`
  position: absolute;
  text-align: right;
  font-family: "Roobert SemiBold";
  font-size: 2rem;
  line-height: 1.8rem;
  margin-top: -325px;
  margin-bottom: 2.2rem;
  flex-grow: 1;
  right: 165px;
  pointer-events: none;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-right: -100px;
      margin-top: -410px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-right: -100px;
      margin-top: -410px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-right: -100px;
      margin-top: -410px;
    }
  }

  ${media.lessThan("medium")`
    margin-top: ${isAndroid ? -134 : -130}px
    margin-right: 30px;
    font-size: 18px;
    line-height: 1rem;
    margin-top: -80px;
    right: 10px;
  `}
`

// More Work

const MoreClient = styled.div`
  margin-bottom: -20px;
  margin-left: 18px;
  margin-right: 18px;

  ${media.lessThan("medium")`
        margin-bottom: -8px;
    `}
`

const MoreWork = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 80px;
  margin-bottom: -40px;
`

const PreviousWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const WorkSpacer = styled.div`
  flex-grow: 1;
`

const NextWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const ButtonArrow = styled.div`
  font-size: 80px;
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  color: #b8b8b8;

  &:hover {
    color: #b8b8b8;
  }

  ${media.lessThan("medium")`
      font-size: 40px;
  `}
`

const CaseStudy = () => {
  let mobileSlider
  const [currentSlide, setCurrentSlide] = useState(0)
  const windowSize = useWindowSize()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  }

  settings.slidesToShow = windowSize.width <= 550 || isMobile ? 1 : 3

  const next = () => {
    mobileSlider.slickNext()
  }

  const previous = () => {
    mobileSlider.slickPrev()
  }

  return (
    <IndexContainer>
      <FixedBackground />
      <PrimaryImageWE />
      <Layout activeSection="work">
        <SEO
          title="Futurehaus: Cleancio Case Study"
          description="Helping a rapidly growing cleaning service expand efficiently"
          url="https://futurehaus.studio/work/cleancio"
          opengraph={socialOG}
        />
        <MainContainer>
          <MainLeft>
            <PrimarySpacer />
            <WorkClient>Cleancio</WorkClient>
            <WorkSubhead>
              Helping a rapidly growing cleaning service expand efficiently
            </WorkSubhead>
            <SectionHeader>Where it started</SectionHeader>
            <SectionContent>
              Cleancio is a successful small business that provides cleaning
              services for vacation rentals. When they wanted a true partner to
              develop the technology to support their rapid growth, they turned
              to Futurehaus.
            </SectionContent>
            <HeaderSpacer />
          </MainLeft>
          <VerticalRule />
          <HorizontalRule />
          <MainRight>
            <FlexSpacer />
            <SectionHeader>Results</SectionHeader>
            <PhaseDisciplineContainer>
              <PhaseDisciplines className="discipline-bullet cleancioBrand">
                App launched successfully
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet cleancioBrand">
                Go-to tool for more than 40 cleaners in <br />
                <div style={{ marginTop: "10px" }}>two cities</div>
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet cleancioBrand">
                Reports increased by up to 700 percent
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet cleancioBrand">
                Overall workload reduced
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet cleancioBrand">
                Higher staff satisfaction
              </PhaseDisciplines>
            </PhaseDisciplineContainer>
            <SectionHeader>Metrics at a glance</SectionHeader>
            <ImpactContainer>
              <SectionImpact>
                <div>
                  <ImpactTotal>700%</ImpactTotal>
                  <ImpactDescription>Increase in efficiency</ImpactDescription>
                </div>
                <div>
                  <ImpactTotal>5</ImpactTotal>
                  <ImpactDescription>
                    Average process
                    <br />
                    steps reduced
                  </ImpactDescription>
                </div>
                <div>
                  <ImpactTotal>1.5K</ImpactTotal>
                  <ImpactDescription>
                    Average monthly <br />
                    cleanings
                  </ImpactDescription>
                </div>
              </SectionImpact>
            </ImpactContainer>
          </MainRight>
        </MainContainer>
        <ShowcaseContainer>
          <ShowcaseX />
          <NextPrev
            darkMode
            mobileScreensOnly
            color="#3bb4cb"
            previous={previous}
            next={next}
          />
          <ProjectCounter>
            <div>0{currentSlide === 0 ? 5 : currentSlide}</div>
            <div>/05</div>
          </ProjectCounter>
          <ShowcaseSliderShadow />
          <ShowcaseSliderMobile
            ref={c => (mobileSlider = c)}
            {...settings}
            beforeChange={(oldIndex, newIndex) => {
              setCurrentSlide(newIndex)
            }}
          >
            <MobileSlide
              key={1}
              className={currentSlide === 0 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="Email to new Cleancios, asking them to download the app. (In Spanish)"
                  src={mobileA}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={2}
              className={currentSlide === 1 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="Onboarding screen describing how reporting is now easier with the Cleancio app."
                  src={mobileB}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={3}
              className={currentSlide === 2 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="The schedule screen of the Cleancio app, where Cleancios can see their latest assignments."
                  src={mobileC}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={4}
              className={currentSlide === 3 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="The messaging screen where Cleancios can contact administrators during a cleaning."
                  src={mobileD}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={5}
              className={currentSlide === 4 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="Confirmation screen showing a finished cleaning."
                  src={mobileE}
                />
              </MobileInner>
            </MobileSlide>
          </ShowcaseSliderMobile>
        </ShowcaseContainer>
        <Fade bottom cascade duration={1250} distance="25px">
          <MiddleContainer>
            <MainLeft style={{ minHeight: 0 }}>
              <SectionHeader>What we did</SectionHeader>
              <SectionContent>
                As always, we began without any assumptions about what would
                best meet Cleancio’s need to more efficiently communicate with
                staff and track results and problems. We dove deep into their
                then-current CRM system to understand what worked and what could
                be improved. Reflecting this research and drawing from several
                existing prototypes, we built a new, mobile House Cleaner app
                with an updated, custom design. We then tested this new
                prototype with cleaning staff to make sure it met their needs.
                The resulting product offers an effortless way for staff to
                share information and complete reports more efficiently. The app
                also enables managers to track cleaning results and address
                problems.
                <br />
                <br />
                Our partnership with Cleancio continued during final testing and
                launch of the new app. We developed a strategic rollout plan,
                including training, to maximize staff adoption and engagement.
                After the successful completion of this new internal-facing app,
                Cleancio is again working with Futurehaus to design and build a
                new custom app for their clients.
              </SectionContent>
              <PrimaryButton
                blank
                darkMode
                topSpacing
                backgroundImage={linkBackgroundImage}
                link="https://www.cleancio.com"
              >
                Learn about Cleancio
              </PrimaryButton>
            </MainLeft>
            <HorizontalRule />
            <MainRight>
              <SectionHeader>Services</SectionHeader>
              <PhaseDisciplineContainer>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  Design and technology strategy
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  Design workshop
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  Research, including stakeholder
                  <br />
                  <div style={{ marginTop: "10px" }}>and expert interviews</div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  Problem definition and feature prioritization
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  Prototyping
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  User experience and visual design
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  Agile product development
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet cleancioBrand">
                  App launch and staff adoption training
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
              <SectionHeader>In collaboration with</SectionHeader>
              <SectionContent>
                <a
                  href="https://flatironschool.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Designation (now Flatiron School)
                </a>
              </SectionContent>
            </MainRight>
          </MiddleContainer>
        </Fade>
        <SectionHorizontalRule />
        <Fade bottom cascade duration={1250} distance="25px">
          <ClientQuote>
            “I’m still speechless. Thank you, Futurehaus, for being one of the
            fundamental stones in making this [app] a reality.”
          </ClientQuote>
          <ClientName>
            Rocio Lane, CEO and Founder
            <br />
            Cleancio
          </ClientName>
        </Fade>
        <Contact small darkMode backgroundImage={linkBackgroundImage} />
        <MoreWork>
          <Fade left distance="20px">
            <AniLink
              to="/work/worldwe"
              cover
              direction="left"
              bg="#222533"
              className="caseLink"
            >
              <PreviousWork>
                <ButtonArrow>←</ButtonArrow>
                <MoreClient>WorldWE</MoreClient>
              </PreviousWork>
            </AniLink>
          </Fade>
          <WorkSpacer />
          <Fade right distance="20px">
            <AniLink
              to="/work/yearup"
              cover
              direction="right"
              bg="#222533"
              className="caseLink"
            >
              <NextWork>
                <MoreClient>Year Up</MoreClient>
                <ButtonArrow>→</ButtonArrow>
              </NextWork>
            </AniLink>
          </Fade>
        </MoreWork>
      </Layout>
    </IndexContainer>
  )
}

export default CaseStudy
