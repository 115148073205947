import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"
import Fade from "react-reveal/Fade"

const StyledImage = styled(Img)`
  display: block;
  width: 100%;
  max-width: 820px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  height: auto;
  pointer-events: none;

  ${media.lessThan("medium")`
    margin-top: 10vh;
  `}
`

const ImageBackground = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  mix-blend-mode: lighten;
`

const PrimaryWorkImage = () => {
  const data = useStaticQuery(graphql`
    query {
      primaryWorkCleancio: file(
        relativePath: { eq: "work-primary-cleancio.png" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ImageBackground>
      <Fade>
        <StyledImage
          fluid={data.primaryWorkCleancio.childImageSharp.fluid}
          alt="Woman smiling and looking up to her left."
        />
      </Fade>
    </ImageBackground>
  )
}

export default PrimaryWorkImage
